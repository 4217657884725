<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
    >
      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        :model="model"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
      >
        <el-form-item
          :label="fields.roles.label"
          :prop="fields.roles.name"
          :required="true"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-select
              multiple
              placeholder
              v-model="model[fields.roles.name]"
            >
              <el-option
                :key="option.value"
                :label="option.label"
                :value="option.value"
                v-for="option in fields.roles.options"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.password.label"
          :prop="fields.password.name"
          :required="true"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input
              :title="i18n('iam.setPasswordAndApprove')"
              autocomplete="off"
              type="input"
              v-model="model[fields.password.name]"
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item>
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="iam.approve.approve"></app-i18n>
            </el-button>

            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { UserModel } from '@/modules/auth/user-model';
import Errors from '@/shared/error/errors';
import { i18n } from '@/i18n';

const { fields } = UserModel;
const formSchema = new FormSchema([
  fields.id,
  fields.password,
  fields.roles,
  fields.updatedAt,
]);

export default {
  name: 'app-iam-approve-user-modal',

  props: ['record', 'visible'],

  data() {
    return {
      model: {},
      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    formSchema() {
      return formSchema;
    },

    title() {
      return i18n('iam.approve.title');
    },

    fields() {
      return fields;
    },
  },

  async created() {
    this.model = this.formSchema.initialValues(this.record || {});
  },

  methods: {
    ...mapActions({
      doApproveUser: 'iam/form/doApproveUser',
    }),

    async doSubmit() {
      try {
        try {
          await this.$refs.form.validate();
        } catch (error) {
          return;
        }

        this.saveLoading = true;
        const values = formSchema.cast(this.model);
        await this.doApproveUser(values);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },

    doCancel() {
      this.$emit('cancel');
    },

    i18n(code) {
      return i18n(code);
    },
  },
};
</script>
